import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px solid #ffffff52',
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: '0px',
    paddingRight: '0px',
    '&.Mui-disabled': {
      opacity: '1',
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
})(MuiAccordionDetails);

function Availability(props) {
  const { data, show_availability_footer_message } = props;
  const [showFooterMessage, setShowFooterMessage] = useState(true);

  useEffect(() => {
    if (show_availability_footer_message === false) {
      setShowFooterMessage(false);
    }
  }, []);

  if (data.length == 0) return null;
  if (data.length == 1 && !data[0].plot_name) return null;
  return (
    <Box>
      <Box mb={6}>
        <Typography variant="h3">Availability</Typography>
      </Box>
      {data.map((item, key) => {
        if (item.plot_status_options == 'Not Released') return null;
        return (
          <Accordion
            key={key}
            disabled={item.plot_status_options != 'Available'}
            //efaultExpanded={item.plot_status}
            square={true}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: '#fff',
                    visibility:
                      item.plot_status_options == 'Available'
                        ? 'visible'
                        : 'hidden',
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box component={Grid} container>
                {item.plot_name && (
                  <Grid item xs>
                    <Typography
                      color="textSecondary"
                      className="text-uppercase"
                    >
                      {item.plot_name}
                    </Typography>
                  </Grid>
                )}
                {item.bedrooms && (
                  <Grid item xs>
                    <Typography color="textSecondary">
                      {item.bedrooms} Bedrooms
                    </Typography>
                  </Grid>
                )}
                <Grid item xs>
                  <Typography
                    className="text-uppercase"
                    align="right"
                    color={
                      item.plot_status_options == 'Available'
                        ? 'textSecondary'
                        : 'textPrimary'
                    }
                  >
                    {item.plot_status_options}
                  </Typography>
                </Grid>
              </Box>
            </AccordionSummary>
            {item.plot_status_options == 'Available' && (
              <AccordionDetails>
                <Box display="flex" alignItems="flex-start" width="100%" pr={1}>
                  <Box mr="auto">
                    {item.price && (
                      <Box>
                        <Typography color="textSecondary">
                          {item.price}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {item.floorplan && item.floorplan?.url && (
                      <Box mr={4}>
                        <a
                          href={item.floorplan.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-link--default"
                        >
                          <Typography color="textSecondary" component="span">
                            Floorplan
                          </Typography>
                        </a>
                      </Box>
                    )}
                    {item.ground_floor && item.ground_floor?.url && (
                      <Box mr={4}>
                        <a
                          href={item.ground_floor.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-link--default"
                        >
                          <Typography color="textSecondary" component="span">
                            Ground Floor
                          </Typography>
                        </a>
                      </Box>
                    )}
                    {item.first_floor && item.first_floor?.url && (
                      <Box mr={4}>
                        <a
                          href={item.first_floor.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-link--default"
                        >
                          <Typography color="textSecondary" component="span">
                            First Floor
                          </Typography>
                        </a>
                      </Box>
                    )}
                    {item.second_floor && item.second_floor?.url && (
                      <Box mr={4}>
                        <a
                          href={item.second_floor.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-link--default"
                        >
                          <Typography color="textSecondary" component="span">
                            Second Floor
                          </Typography>
                        </a>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Link to="/contact-us" className="text-link--default">
                      <Typography color="textSecondary" component="span">
                        Enquire
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </Box>
  );
}

Availability.propTypes = {
  data: PropTypes.array.isRequired,
  show_availability_footer_message: PropTypes.bool,
};

export default Availability;
