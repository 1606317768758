import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    body.menuOpen {
        overflow: hidden;
    }
    body.scrollFixed {
        overflow: hidden;
    }
    .w-100 {
        width: 100%;
    }
    .spaced-grid-wrapper {
        height: auto;
        overflow: hidden;
    }
    .content-column-block .block-img {
        padding-top: 3rem;
        padding-right: 3rem;
    }
    .rec-slider-container {
        margin: 0px;
    }
    .rec-carousel-wrapper {
        position: relative;
    }
    .image-slideshow .rec-pagination {
        position: absolute;
        bottom: 40px;
        right: 40px;
        margin: 0;
        padding: 0;
        background: #282827;
        padding: 0.5rem 3rem;
        display: flex;
        align-items: center;
    }
    .rec-dot {
        background-color: #fff;
        border: transparent;
        width: 15px;
        height: 15px;
    }
    .rec-dot.rec-dot_active {
        background-color: #A8977B;
        border-color: #A8977B;
        box-shadow: none;
        width: 18px;
        height: 18px;
    }
    .rec-carousel-item {
        display: flex;
    }
    .text-uppercase {
        text-transform: uppercase;
    }
    .text-link--default {
        text-decoration: underline;
        text-decoration-color: #fff;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        &:hover {
            opacity: 0.5;
        }
    }
    .gold-light {
        color: #BEA169;
    }
    .body-text-link {
        text-decoration: none;
    }
    .capatilise {
        text-transform: capitalize;
    }
    .body-text-link {
        color: #BEA169;
    }
    .carousel {
        position: relative;
        padding-left: 70px;
        padding-right: 80px;
    }
    @media screen and (max-width: 900px) {
        .carousel {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .select-location-button {
        transition: opacity 0.2s ease-in-out;
    }
    .select-location-button:hover {
        opacity: 0.7;
    }
    .standard-button {
            text-decoration: none;
    color: rgb(190, 161, 105);
    font-size: 1.5rem;
    border: 1px solid rgb(189, 160, 104);
    padding: 10px 25px;
    margin-top: 40px;
        display: inline-block;
    }
    .awards-block .block-img {
        width: 33.333%;
    }
    .awards-block .block-img img {
        max-width: 100%;
    }
`;
