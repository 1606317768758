import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Optional: simple inline styles for the overlay
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
};

const imageStyles = {
  maxWidth: '90%',
  maxHeight: '90%',
  cursor: 'zoom-out',
};

function Sitemap(props) {
  const { data } = props;
  const [isOpen, setIsOpen] = useState(false);

  const openOverlay = () => setIsOpen(true);
  const closeOverlay = () => setIsOpen(false);

  return (
    <Box>
      <Box mb={6}>
        <Typography variant="h3">Sitemap</Typography>
      </Box>
      <img
        className="w-100"
        src={data.url}
        alt={data.alt ? data.alt : ''}
        style={{ cursor: 'zoom-in' }}
        onClick={openOverlay}
        aria-hidden="true"
      />

      {isOpen && (
        <div style={overlayStyles} onClick={closeOverlay} aria-hidden="true">
          <img
            src={data.url}
            alt={data.alt ? data.alt : ''}
            style={imageStyles}
          />
        </div>
      )}
    </Box>
  );
}

Sitemap.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sitemap;
